import { ElementRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppComponent } from 'src/app/app.component';
import { UserregisterService } from './userregister.service';
import { Usersignup } from './usersignup.model';
import { reject } from 'q';

@Component({
  selector: 'app-userregister',
  templateUrl: './userregister.component.html',
  styleUrls: ['./userregister.component.css']
})
export class UserregisterComponent implements OnInit {
  result : any;
  userRegisterAddForm: FormGroup;
  Usersignupmodel: any;
  Usersignup : Usersignup;
  constructor(
    private formBuilder:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private service:UserregisterService,
  ) {
    this.userRegisterAddForm = this.formBuilder.group({
      'firstName' : ['',[Validators.required,Validators.minLength(1)]],
        'lastName' : ['',[Validators.required,Validators.minLength(1)]],
        'phoneno' : ['',[Validators.required,Validators.minLength(1)]],
        'email' : ['',[Validators.required,Validators.minLength(1),Validators.email]],
        'password' : ['',[Validators.required,Validators.minLength(1)]]
    });
   }

  ngOnInit() {
  }


  userregisterAdd(){
    console.log(this.userRegisterAddForm.controls);
    if(this.userRegisterAddForm.valid){
      var sF = this.userRegisterAddForm.value;
      this.Usersignupmodel = new Usersignup(
        sF.firstName,
        sF.lastName,
        sF.email,
        sF.password,
        sF.phoneno,
        ''
      );
      
        this.service.UserRegisterAdd(this.Usersignupmodel).subscribe((result) => {
          console.log(result.body);
          if (result.body['error'] === 'false') {
            environment.swalalert('success',result.body['msg']).then(value => {
              if(value){
                this.router.navigate(['user'])
              }
            })
            
          }else{
            environment.swalalert('error',result.body['error_message']);  
          }
        },
        (error) => {
            console.error(error);
            reject(error);
        });
    }else{
      environment.swalalert('error','Validation Required');  
    }
    
  }
}
