import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-providersignup',
  templateUrl: './providersignup.component.html',
  styleUrls: ['./providersignup.component.css']
})
export class ProvidersignupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
