import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common.service';

@Component({
  selector: 'app-home-page-new',
  templateUrl: './home-page-new.component.html',
  styleUrls: ['./home-page-new.component.css']
})
export class HomePageNewComponent implements OnInit {

  constructor(private commonservice:CommonService) { }

  ngOnInit() {
    console.log('i am main');
    
  //clearInterval(this.commonservice.interval);
  }

}
