import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
@Injectable({
  providedIn: 'root'
})
export class UserService {

    url = environment.Url;
  httpheaders = new HttpHeaders({
 
    'Content-Type': 'application/json',
   'Accept': 'application/json',
   
   
  });

  constructor(private http:HttpClient,
    private cookie: CookieService) { }

     
     Userforgotpassword(data):any{
          
          return this.http.post(`${this.url}forgotpassword`,data,{
            headers: this.httpheaders,
            observe: 'response'
          });
         
          // return Observable.of({'body':{error: "false",
          // error_message: "success"}});
          
          
        } 

        forgotpasswordotpcheck(data){
   return this.http.post(`${this.url}forgotpasswordotpcheck`,data,{
            headers: this.httpheaders,
            observe: 'response'
          });
        }
        resetpassword(data){
          return this.http.post(`${this.url}resetpassword`,data,{
                   headers: this.httpheaders,
                   observe: 'response'
                 });
               }
}
