import Swal from 'sweetalert2';
import { NgProgressModule } from 'ngx-progressbar';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBNpL1fke2skl8SsjZRnwYiUQ4ArNByxIA",
    authDomain: "homerunners-92f4a.firebaseapp.com",
    projectId: "homerunners-92f4a",
    storageBucket: "homerunners-92f4a.appspot.com",
    messagingSenderId: "706644335370",
    appId: "1:706644335370:web:9f19fd6b2e53a434cdf8f3",
    measurementId: "G-9V7SM6W00Q"
  },
  serverurl: 'https://homesrunner.com/uberdoo/public/api/'
  ,
  Url: 'https://homesrunner.com/uberdoo/public/api/',
  ProviderUrl: ' https://homesrunner.com/uberdoo/public/provider/',

  swalalert(type, msg) {
    if (type === 'success') {
      return new Promise(resolve => {
        Swal.fire({
          type: 'success',
          icon: 'success',
          title: 'Success',
          text: msg,
          showConfirmButton: false,
          timer: 1500
        }).then(async (result) => {
          resolve(true);
        });
      });
    } else if (type === 'delete') {
      return new Promise(resolve => {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Logout ' + msg + ' it!'
        }).then(async (result) => {
          if (result.value) {
            Swal.fire({
              title: msg + '!',
              text: 'Your file has been ' + msg + '.',
              type: 'success',
              showConfirmButton: false,
              timer: 1000
            }).then(async (result) => {
              resolve(true);
            })
          }
        })
      });
    } else if (type === 'warning') {
      return new Promise(resolve => {
        Swal.fire({
          title: 'Are you sure?',
          text: "Want to logout!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes '
        }).then(async (result) => {
          if (result.value) {
            Swal.fire({
              title: '',
              text: 'Logging Out',
              type: 'success',
              showConfirmButton: false,
              timer: 1000
            }).then(async (result) => {
              resolve(true);
            })
          }
        })
      });
    } else if (type === 'withoutlogin') {
      return new Promise(resolve => {
        Swal.fire({
          title: 'Are you sure?',
          text: "Please Login!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Login ' + msg + ' it!'
        }).then(async (result) => {
          if (result.value) {
            Swal.fire({
              title: msg + '!',
              text: 'Loading ' + msg + '.',
              type: 'success',
              showConfirmButton: false,
              timer: 1000
            }).then(async (result) => {
              resolve(true);
            })
          }
        })
      });
    }

    else if (type === 'nodata' || type === 'underconst') {
      Swal.fire({
        title: '<strong>' + msg + ' <u>!!!</u></strong>',
        type: 'info',
        html: 'You can use <b>' + msg + '</b>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Great!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText:
          '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down',
      })
    } else if (type === 'loader') {
      // return new Promise(resolve => {
      //   setTimeout(function () {
      //     Swal.fire(msg);
      //   Swal.showLoading()
      // }, 500);
      // });
    }
    else {
      Swal.fire({
        type: type,
        title: 'OOPS!',
        icon: type,
        text: msg,
        showConfirmButton: true,
      });
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
