import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs'
import { environment } from 'src/environments/environment';
import { ToastrManager } from 'ng6-toastr-notifications';
@Injectable()
export class MessagingService {
currentMessage = new BehaviorSubject(null);
url = environment.Url;
firetoken;
httpheaders = new HttpHeaders({
  'Authorization': "Bearer "+this.cookie.get('Token'),
  'Content-Type': 'application/json',
 'Accept': 'application/json',

 
});
constructor(private http:HttpClient,private angularFireMessaging: AngularFireMessaging,private cookie: CookieService,  public toastr: ToastrManager) {
this.angularFireMessaging.messaging.subscribe(
(_messaging) => {
_messaging.onMessage = _messaging.onMessage.bind(_messaging);
_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
}
)
}
 requestPermission() {

  this.angularFireMessaging.requestToken.subscribe(
(token) => {
console.log(token);
this.firetoken=token;
console.log(this.firetoken);
const data={fcm_token:this.firetoken,os:'web'}
console.log(data);

this.updatedevicetoken(data).subscribe(res=>{})

//return firetoken;
},
(err) => {
console.error('Unable to get permission to notify.', err);
}

);
 console.log(this.firetoken);
// return new Promise((resolve ,reject)=>{
//  resolve(firetoken)
// });

}
updatedevicetoken(data){
 return this.http.post(`${this.url}updatedevicetoken`,data,{
    headers: this.httpheaders,
        observe: 'response'
  });
}
receiveMessage() {
  console.log('i amreceive');
  this.angularFireMessaging.messages.subscribe(
(payload) => {
console.log("new message received. ", payload);
this.showToast(payload['data'].message ,payload['data'].title, 'successToastr')
this.currentMessage.next(payload);
})
}


showToast(message, title, key) {
  // successToastr
  // errorToastr
  // warningToastr
  // infoToastr
  var option = {
    position: 'top-center',
      showCloseButton: true,
      maxShown: 5,
      toastTimeout: 5000,
      newestOnTop: true,
      animate: 'slideFromTop'

      
  }
  this.toastr[key](message, title, option);
}
}