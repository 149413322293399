import { Injectable } from '@angular/core';
import { Router,CanActivate,ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class GuardGuard implements CanActivate{
  constructor(private router: Router,
    private cookie: CookieService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.cookie.get('Token')) {
      // logged in so return true
      return true;
  } else {
    this.router.navigate([''], {
      queryParams: {
        return: state.url
    }
  });

  return false;
}
  }
}
