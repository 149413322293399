import { Component, OnInit } from '@angular/core';
import { Route,Router,ActivatedRoute,CanActivate,RouterStateSnapshot } from '@angular/router';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../../common.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {


  logged: boolean = false;
  constructor(
    private router : Router,
    private cookie: CookieService,
    private commonservice:CommonService
  ) { }

  ngOnInit() {
    if (this.cookie.get('Token')) {
      this.logged = true;
    }
  }
  logout(){
    
    
    this.cookie.deleteAll();
    this.router.navigate(['']);
    clearInterval(this.commonservice.interval);
  }
}
