import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from './../../common.service';
import { AccountnavbarService } from './accountnavbar.service';


@Component({
  selector: 'app-accountnavbar',
  templateUrl: './accountnavbar.component.html',
  styleUrls: ['./accountnavbar.component.css']
})
export class AccountnavbarComponent implements OnInit {
  isLogged: boolean = false;  
  firstname: String;
  lastname: String;
  images: string;
  result: any;
  imagefilename: any;



  constructor(private router: Router,
    private cookie: CookieService,
    private service : AccountnavbarService,
    public commonservice:CommonService) { }

  ngOnInit() {
    this.getUserDetails();
  

  }
  menuclicked(data){
    console.log('ioooooo');
    if(data=='address'){
    this.service.accountnav.next('address')
    }else if(data=='profile'){
      this.service.accountnav.next('profile')
    }
  
  }
  getUserDetails(){
    if (this.cookie.get('Token')) {
      this.isLogged = true;
      this.firstname = this.cookie.get('first_name');
      this.lastname = this.cookie.get('last_name');
      this.images = this.cookie.get('image');
      
    }
  }


  logout(){
    this.cookie.deleteAll();
    clearInterval(this.commonservice.interval);
    this.router.navigate(['']);

  }
  
}
