import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProviderloginService {

  url = environment.ProviderUrl;
  httpheaders = new HttpHeaders({
 
    'Content-Type': 'application/json',
   'Accept': 'application/json'
   
  });
  constructor(private http:HttpClient,
    private cookie: CookieService) { }

    // User login
    provider_login_auth(data){

      return this.http.post(`${this.url}providerlogin`,data,{
        headers: this.httpheaders,
            observe: 'response'
      });
    }

}
