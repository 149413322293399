import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { AccountService } from './landingpage/account/account.service';
import { MessagingService } from './messaging.service';
import { ToastrManager } from 'ng6-toastr-notifications';
declare let $: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'homesrunners web app';
    isLogged: boolean = false;
    //modal declarations
    modelview: any = {}
    //activepaymethod=[true,false]
    iscash: boolean = false;
    iscard: boolean = false;
    couponcode: any;
    paymenttypes: any = []
    waitingforconfirmation: boolean = false;
    jobimage: any = {};
    feedback: any;
    rating: any;
    iscouponapplied: boolean = false;
    invoice: any;
    thankstext: boolean = false;

    message;
    //modal declarations end
    constructor(private router: Router,
        private cookie: CookieService,
        private commonservice: CommonService,
        private messagingService: MessagingService,
        public toastr: ToastrManager
    ) {


    }

    ngOnInit() {

        if (this.cookie.get('Token')) {
            this.isLogged = true;

            // this.messagingService.requestPermission()
            this.messagingService.receiveMessage()
            this.message = this.messagingService.currentMessage
            console.log(this.message)
            // common invoice modal 
            this.commonservice.interval = setInterval(() => {

                this.listpaymentmethod()
                this.commonservice.app_settings().subscribe(res => {
                    if (res.body['error'] == 'false') {
                        // console.log(res['body']['status'].length==0);

                        // console.log(res['body']['all_bookings']);
                        //  console.log(res['body']['status'][0].status);

                        // res['body']['all_bookings'].map(res=>{
                        if (res['body']['status'].length > 0) {
                            if (res['body']['status'][0].status == "Completedjob") {
                                // console.log(res.id);
                                this.modelview = res['body']['status'][0];
                                $(document).ready(function() {
                                    $("#myinvoiceModal").modal();
                                });
                                //  $('#myinvoiceModal').modal({backdrop: 'static', keyboard: false}) 
                            } else if (res['body']['status'][0].status == "Waitingforpaymentconfirmation") {
                                // console.log(res.id);
                                this.modelview = res['body']['status'][0];
                                $(document).ready(function() {
                                    $("#myinvoiceModal").modal();
                                });
                                //  $('#myinvoiceModal').modal({backdrop: 'static', keyboard: false}) 
                            } else if (res['body']['status'][0].status == 'Reviewpending') {
                                this.modelview = res['body']['status'][0];
                                this.startjobendjobdetails()

                                $(document).ready(function() {
                                    $("#myinvoiceModal").modal();
                                });
                                //   $('#myinvoiceModal').modal({backdrop: 'static', keyboard: false}) 
                            } else if (this.thankstext = true) {
                                $(document).ready(function() {
                                    $("#myinvoiceModal").modal();
                                });
                            } else if (res['body']['status'].length == 0) {
                                $('#myinvoiceModal').modal('hide');
                                console.log('iam empty');

                            }
                        } else {
                            //  this.modalclose()
                            $(document).ready(function() {


                                $('#myinvoiceModal').modal('hide');

                            });
                            //  if(res.body['error']=='false'){
                            //           console.log('iam empty');
                            //           $('#myinvoiceModal').modal('hide');
                            //  }
                        }
                        //  })

                    } else {

                    }
                })

            }, 8000);

            // common invoice modal end
        }


    }
    //common modal functions
    paymentmethod(data) {
        this.paymenttypes.forEach(element => {
            element.addressactive = false
        });
        data.addressactive = !data.addressactive;
        if (data.payment_name == 'cash') {
            this.iscash = true
            this.iscard = false
        } else {
            this.iscash = false
            this.iscard = true
        }
    }
    sendreview(data) {
        console.log('review', data);


        const apidata: any = {}
        apidata.booking_id = data.booking_id
        apidata.id = data.provider_id
        apidata.rating = this.rating;
        apidata.feedback = this.feedback
        this.commonservice.sendreview(apidata).subscribe(res => {
            if (res.body['error'] == 'false') {
                this.rating = undefined;
                this.feedback = undefined;
                this.thankstext = true;
                this.modelview.status = 'Thanks'
                $(document).ready(function() {


                    $('#myinvoiceModal').modal('hide');

                });
                environment.swalalert('success', res.body['msg']).then(value => {
                    //  $("[data-dismiss=modal]").trigger({ type: "click" });

                });
            } else {
                environment.swalalert('error', res.body['error_message']);
            }
        })
    }
    Thanks() {
        this.thankstext = false;
        window.location.reload();
    }
    startjobendjobdetails() {
        const apidata: any = { booking_id: this.modelview.booking_id }

        this.commonservice.startjobendjobdetails(apidata).subscribe(res => {
            if (res.body['error'] == 'false') {
                this.jobimage = res['body']['data'];
                this.invoice = res['body']['invoicelink'][0].invoicelink;
                console.log(res['body']['invoicelink'][0].invoicelink);


            } else {
                environment.swalalert('error', res.body['error_message']);
            }
        });
    }
    listpaymentmethod() {
        // debugger
        this.commonservice.list_payments().subscribe(res => {
            if (res.body['error'] == 'false') {
                this.paymenttypes = res.body['payment_types'];

                this.paymenttypes.map(res => {
                    if (res.payment_name == 'cash') {
                        res.faicon = '<i class="fa  fa-money-bill fa-7x" style="color: black;cursor: pointer;" ></i>'
                    } else if (res.payment_name == 'card') {
                        res.faicon = '<i class="fa  fa-credit-card fa-7x" style="color: black;cursor: pointer;" ></i> '
                    }

                })

            } else {
                environment.swalalert('error', res.body['error_message']);
            }
        })
    }
    confirmpayment(data) {
        // debugger
        const apidata: any = { id: data }
        if (this.iscash) {
            apidata.method = 'cash'


        } else if (this.iscard) {
            apidata.method = 'card'
        }


        this.commonservice.pay(apidata).subscribe(res => {
            if (res.body['error'] == 'false') {
                environment.swalalert('success', res.body['msg']).then(value => {
                    //  $("[data-dismiss=modal]").trigger({ type: "click" });
                    this.waitingforconfirmation = true;
                });
            } else {
                environment.swalalert('error', res.body['error_message']);
            }
        })
    }
    applycoupon(data) {

        const apidata = { couponname: this.couponcode, booking_order_id: data }
        this.commonservice.apply_coupon(apidata).subscribe(res => {
            if (res.body['error'] == 'false') {
                this.iscard = true;

                // this.iscouponapplied = true;
                environment.swalalert('success', res.body['msg']).then(value => {
                    //  $("[data-dismiss=modal]").trigger({ type: "click" });

                });
            } else {
                environment.swalalert('error', res.body['error_message']);
            }
        })

    }
    removecoupon(data) {

        const apidata = { couponname: this.couponcode, booking_order_id: data }
        this.commonservice.remove_coupon(apidata).subscribe(res => {
            if (res.body['error'] == 'false') {

                this.iscouponapplied = false;
                this.couponcode = undefined;
                environment.swalalert('success', res.body['msg']).then(value => {
                    //  $("[data-dismiss=modal]").trigger({ type: "click" });

                });
            } else {
                environment.swalalert('error', res.body['error_message']);
            }
        })


    }
    modalclose() {
        $(document).ready(function() {


            $('#myinvoiceModal').modal('hide');

        });

        //  $('#myinvoiceModal').modal('hide');

    }
    //common modal functions end
}
