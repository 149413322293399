import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {
  url = environment.Url;
  httpheaders = new HttpHeaders({
 
    'Content-Type': 'application/json',
   'Accept': 'application/json'
   
  });
  constructor(private http:HttpClient,
    private cookie: CookieService) { }

    searchlist1(){
      return this.http.get(`${this.url}homedashboardnew`,{
        headers: this.httpheaders,
            observe: 'response'
      });
    }

    subcategorylist(category_id){
      console.log(category_id,'cate_id');
      return this.http.post(`${this.url}list_subcategory`,category_id,{
        headers: this.httpheaders,
            observe: 'response'
      });
    }
}
