import { Component, OnInit } from '@angular/core';
import { AccountnavbarComponent } from '../accountnavbar/accountnavbar.component';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { HomepageService } from './homepage.service';
import { Homepage } from './homepage.model';
import { environment } from 'src/environments/environment';
//import * as $ from 'src/assets/js/jquery.min.js';
declare let $:any;
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  searchCategoryForm: FormGroup;
  HomepageModel: any;
 
  btnDisable: boolean = true;
sF: any;
  category: any;
  sub_category: any;
  constructor(
    private accountHome: AccountnavbarComponent,
    private router: Router,
    private formBuilder : FormBuilder,
    private cookie: CookieService,
    private service : HomepageService,
    private profilenavbar: AccountnavbarComponent
  ) {
    
   }
   
  ngOnInit() {
   


    this.searchCategoryForm = this.formBuilder.group({
      'category' : ['',[Validators.required,Validators.minLength(1)]],
      'subCategory' : ['',[Validators.required,Validators.minLength(1)]]
    });
    // console.log(this.searchCategoryForm.controls);
            // list view category
    this.service.searchlist1().subscribe((result) =>{
      console.log(result.body['list_category']);
      this.category = result.body['list_category'];
    
    },
    (error) => {
      console.error(error);
    }
    );
           
      }
     
      onChange(event){
        if(event){
          this.btnDisable = false;
          var newVal = event.target.value;
          console.log(newVal,'newVal');
          var sub_cat = {
              id:newVal
          }
          console.log("event called",newVal)
          this.cookie.set('category_id', newVal)
          this.service.subcategorylist(sub_cat).subscribe((result) =>{
              console.log(result.body);
              this.sub_category = result.body['list_subcategory'];
          });
        }
      }
      subCatOnchange(event){
        var subCat = event.target.value;
        console.log(subCat,'subcatid');
        this.cookie.set('sub_catagory_id', subCat);
        //   this.cookie.set('catagory_id', this.searchCategoryForm.value.category)
          console.log('this.searchCategoryForm.value',this.searchCategoryForm.value)
      }
  search(){

   
    if(this.searchCategoryForm.valid)
    {
      if(this.cookie.get('Token'))
      {
        var sF = this.searchCategoryForm.value;
        this.HomepageModel = new Homepage(
          sF.id,
          sF.category,
          sF.subCategory
        );
        this.router.navigate(['schedule']);
      }else{
        // environment.swalalert('error','Please Login'); 
        environment.swalalert('withoutlogin','Please login').then(value => {
          if(value){
            this.router.navigate(['user']);
          }
        });
      }
    }
    else{

      environment.swalalert('error','Validation Required');  
    }
 
  }
  countrylist(){
    
  }
  
}
