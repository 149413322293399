export class Account {
    public first_name : String;
    public last_name : String;
    public mobile : number;
    public image : any;

    constructor( 
        first_name: String,
        last_name: String,
        mobile: number,
        image: any
         ) {
    this.first_name = first_name;
    this.last_name = last_name;
    this.mobile = mobile;
    this.image = image;
   
    }
}
