export class Usersignup {
    public first_name : String ;
    public last_name: String;  
    public email : String ; 
    public password : any ; 
    public mobile: String;
    public image: String;
    
    constructor( 
        
        first_name: String,
        last_name: String,
        email: String,
        password: any,
        mobile : String,
        image : String,
         ) {
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.password = password;
    this.mobile = mobile;
    this.image = image;

  
    
    }
}
