import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  url = environment.Url;

  httpheadersimage = new HttpHeaders({
    // 'Content-Type': [],
    // 'Accept': 'application/json',
    'Authorization': 'Bearer ' + this.cookie.get('Token').split('|')[0]
  });

  httpheaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + this.cookie.get('Token').split('|')[0]
  });
  constructor(private http: HttpClient,
    private cookie: CookieService) { }

  // fileupload
  fileupload(data: File) {

    const formData: FormData = new FormData();
    formData.append('file', data, data.name);
    console.log('formData', formData)

    return this.http.post(`${this.url}imageupload`, formData, {
      headers: this.httpheadersimage,
      observe: 'response'
    });
  }

  getbidslist(data) {
    return this.http.post(`${this.url}list_biding_quote`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  acceptbid(data) {
    return this.http.post(`${this.url}accept_biding_quote`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  // end fileupload
  //View-profile
  viewprofile() {
    return this.http.get(`${this.url}viewprofile`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
  // Viewbookings
  view_bookings() {
    return this.http.get(`${this.url}view_bookings`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
  listaddress() {
    return this.http.get(`${this.url}listaddress`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  addaddress(data) {

    return this.http.post(`${this.url}addaddress`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
  editaddress(data) {

    return this.http.post(`${this.url}updateaddress`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  deleteaddress(data) {

    return this.http.post(`${this.url}deleteaddress`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  cancelbooking(data) {
    return this.http.post(`${this.url}cancelbyuser`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });


  }
  updateProfile(id) {
    const httpheaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + this.cookie.get('Token').split('|')[0]
    });
    return this.http.post(`${this.url}updateprofile`, id, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
}
