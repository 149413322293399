import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterLinkActive } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Userlogin } from './userlogin.model';
import { UserloginService } from './userlogin.service';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { reject } from 'q';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { MessagingService } from 'src/app/messaging.service';
//import { MessagingService } from './../../messaging.service';

@Component({
  selector: 'app-userlogin',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.css']
})
export class UserloginComponent implements OnInit {

  userLoginForm: FormGroup;
  Userlogin: any;
  Userloginmodel: any;
  UserloginService: any;
  message: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private service: UserloginService,
    private cookie: CookieService,
    private authService: AuthService,

    private messagingService: MessagingService
  ) {
    this.userLoginForm = this.formBuilder.group({
      'email': ['', [Validators.required, Validators.minLength(1), Validators.email]],
      'password': ['', [Validators.required, Validators]]
    });
  }

  ngOnInit() {
    // this.returnUrl = this.route.snapshot.queryParams['return'] || 'dashboard';    
    // if (this.cookie.get('Token')) {
    //   this.router.navigateByUrl(this.returnUrl);
    // } 
  }
  //random token
  random() {
    // set the length of the string
    var stringLength = 10;

    var stringArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '!', '?'];
    var rndString = "";

    for (var i = 0; i < stringLength; i++) {
      var rndNum = Math.ceil(Math.random() * stringArray.length) - 1;
      rndString = rndString + stringArray[rndNum];
    };
    return rndString;
  }
  //end random token
  signInWithGoogle(): void {
    console.log('googlesignin');

    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      console.log(x)



      // this.cookie.set('Token', x.idToken) //+ '|' + this.random())
      this.cookie.set('first_name', x.firstName);
      this.cookie.set('last_name', x.lastName);
      this.cookie.set('email', x.email);
      this.cookie.set('image', x.photoUrl);
      const data = { email: x.email, firstname: x.firstName, lastname: x.lastName, social_type: 'google', socialtoken: x.id, file: x.photoUrl }
      this.service.sociallogin(data).subscribe((result) => {
        // console.log(result.body);
        if (result.body['error'] === 'false') {
          this.cookie.set('Token', result.body['access_token']) //+ '|' + this.random())

          this.cookie.set('userid', result.body['id']);
          environment.swalalert('success', result.body['msg']).then(value => {
            if (value) {
              this.router.navigate(['home'])
            }
          });
        } else {
          environment.swalalert('error', result.body['error_message']);
        }

      },
        (error) => {
          console.error(error);
          reject(error);
        }
      );




    });

  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {
      console.log(x)



      // this.cookie.set('Token', x.idToken) //+ '|' + this.random())
      this.cookie.set('first_name', x.firstName);
      this.cookie.set('last_name', x.lastName);
      this.cookie.set('email', x.email);
      this.cookie.set('image', x.photoUrl);
      const data = { email: x.email, firstname: x.firstName, lastname: x.lastName, social_type: 'facebook', socialtoken: x.id, file: x.photoUrl }
      this.service.sociallogin(data).subscribe((result) => {
        // console.log(result.body);
        if (result.body['error'] === 'false') {
          this.cookie.set('Token', result.body['access_token']) //+ '|' + this.random())

          this.cookie.set('userid', result.body['id']);
          environment.swalalert('success', result.body['msg']).then(value => {
            if (value) {
              this.router.navigate(['home'])
            }
          });
        } else {
          environment.swalalert('error', result.body['error_message']);
        }

      },
        (error) => {
          console.error(error);
          reject(error);
        }
      );


    });

  }

  signOut(): void {
    this.authService.signOut();
  }
  async login() {
    // console.log(this.userLoginForm.controls);
    if (this.userLoginForm.valid) {
      var login = this.userLoginForm.value;
      this.Userloginmodel = new Userlogin(
        login.email,
        login.password
      );
      var data: any = {
        email: this.Userloginmodel.email,
        password: this.Userloginmodel.password,
        user_type: "User"
      };

      this.service.login_auth(data).subscribe((result) => {
        // console.log(result.body);

        if (result.body['error'] === 'false') {
          this.cookie.set('Token', result.body['access_token']) //+ '|' + this.random())
          this.cookie.set('first_name', result.body['first_name']);
          this.cookie.set('last_name', result.body['last_name']);
          this.cookie.set('email', result.body['email']);
          this.cookie.set('image', result.body['image']);
          this.cookie.set('userid', result.body['id']);
          const token = this.messagingService.requestPermission()
          this.messagingService.receiveMessage()
          this.message = this.messagingService.currentMessage
          console.log(this.message)
          console.log('loginpage', token);
          environment.swalalert('success', result.body['msg']).then(value => {
            if (value) {


              this.router.navigate(['home'])

            }
          });
        } else {
          environment.swalalert('error', result.body['error_message']);
        }

      },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    } else {
      environment.swalalert('error', 'Validation Required');
    }
  }

}
