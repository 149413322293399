import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbartwo',
  templateUrl: './navbartwo.component.html',
  styleUrls: ['./navbartwo.component.css']
})
export class NavbartwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
