import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute,RouterLinkActive } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

import { UserService } from './../user.service';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { reject } from 'q';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {

  resetPasswordForm : FormGroup;
  
  constructor(private formBuilder : FormBuilder,
    private router : Router,
    private route : ActivatedRoute,
    private service : UserService,
    private cookie: CookieService) { }

  ngOnInit() {

    this.resetPasswordForm = this.formBuilder.group({
      'email':[ localStorage.getItem('forgotemail')],
      'password' : ['',[Validators.required,Validators.minLength(1)]],
      'confirmpassword' : ['',[Validators.required,Validators.minLength(1)]],
    });
  }
  ngOnDestroy(){
    localStorage.removeItem('forgotemail');
  }
  resetpassword(){
    // console.log(this.userLoginForm.controls);
      if(this.resetPasswordForm.valid)
      {
       
        
        this.service.resetpassword(this.resetPasswordForm.value).subscribe((result) =>{
        
          if(result.body['error'] == 'false')
          {
          
  
            environment.swalalert('success',result.body['error_message']).then(value => {
              if(value){

                localStorage.setItem('forgotemail',this.resetPasswordForm.value.email)
                this.router.navigate(['/user'])
              }
            });
          }else {
            environment.swalalert('error',result.body['error_message']);
            }
          
        },
          (error) => {
            console.error(error);
            reject(error);
          }
        );
      }else{
        environment.swalalert('error','Validation Required');  
      }
    }
}
