import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  url = environment.Url;
  httpheaders = new HttpHeaders({
    'Authorization': "Bearer "+this.cookie.get('Token'),
    'Content-Type': 'application/json',
   'Accept': 'application/json',

   
  });
  constructor(private http:HttpClient,
    private cookie: CookieService) { }

  
    listprovider(data){
    
      return this.http.post(`${this.url}listprovider`,data,{
        headers: this.httpheaders,
            observe: 'response'
      });
    }
    bookprovider(data){
    
      return this.http.post(`${this.url}newbooking`,data,{
        headers: this.httpheaders,
            observe: 'response'
      });
    }
}
