import { Component, ElementRef, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
//import * as $ from 'src/assets/js/jquery.min.js';
import { ScheduleService } from './schedule.service';
import { MapsAPILoader } from '@agm/core';
import { CommonService } from '../../common.service';
import { Socket } from 'ngx-socket-io';
declare let $: any;
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  uploadedImageURLs: { url: string, type: string }[] = []

  isLogged: boolean = false;
  listaddress: any;
  useraddress: any;
  datearray: any = [];
  isaddressadd = false;
  addressForm: FormGroup;
  latitude: number = 11.664325;
  longitude: number = 78.1460142;
  zoom: number;
  address: any;
  private geoCoder;
  coordinates = {}
  @ViewChild('search')
  public searchElementRef: ElementRef;
  subcategoryid: any;
  timingid: any;
  bookingdate: any;
  bookingcity: any;
  bookinglatitude: any;
  bookinglongitude: any;
  description: any;
  image_URL: any;
  hours: any = 0;
  selectedImages: { file: File, url: string, type: string }[] = []
  mins: any = 0;
  file: any;
  profileimagename: File[] = [];
  // profileimagename: any = "";
  imagefilename: any;
  file1: any;

  constructor(private router: Router,
    private socket: Socket,
    private cookie: CookieService,
    private formBuilder: FormBuilder,
    private service: ScheduleService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private commonservice: CommonService) { }
  gotobookings() {
    $('#ondemandacceptModal').modal('hide');
    this.router.navigate(['myaccount'])
  }
  gotobookingsbiding() {
    $('#ondemandbidingModal').modal('hide');
    this.router.navigate(['myaccount'])
  }
  openBidingModel() {
    $('#biddingformModel').modal('show');
  }
  ngOnInit() {
    this.subcategoryid = this.cookie.get('sub_catagory_id')
    console.log('iam subcategory', this.subcategoryid);

    //map
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);


      console.log(autocomplete);

      autocomplete.addListener("place_changed", () => {


        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log(place);
          this.address = place.formatted_address

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
    this.addresstimings();
    this.userviewaddress();
    $('.timeslot ul li .pointer').click(function() {

      $('.timeslot ul li .pointer').removeClass('spanactive');
      $(this).addClass('spanactive');

    });
    if (this.cookie.get('Token')) {
      this.isLogged = true;
    }
    this.date()


    this.addressForm = this.formBuilder.group({
      'id': [''],
      'title': ['', [Validators.required, Validators.minLength(1)]],
      'doorno': ['', [Validators.required, Validators.minLength(1)]],
      'landmark': ['', [Validators.required, Validators.minLength(1)]],
      'address': ['', [Validators.required, Validators.minLength(1)]],
      'latitude': [],
      'longitude': []
    });


    // common invoice modal end
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {


      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;


        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  date() {
    var d1;
    var curr = new Date;
    var first = curr.getDate();



    for (var i = 0; i < 7; i++) {
      var next = new Date(curr.getTime());
      next.setDate(first + i);
      d1 = next.toString();
      console.log('jbdsajk', d1)
      console.log(d1);

      var date = new Date(d1),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);


      var dayname = date.toLocaleString("default", { weekday: "short" })
      console.log("datedayname", dayname);
      console.log('date', day);
      var formatdate = [date.getFullYear(), mnth, day].join("-");
      console.log('sss', formatdate);

      const dateobj = { dayname: dayname, date: day, formatdate: formatdate }
      console.log('dobj', dateobj);
      this.datearray.push(dateobj);

      console.log(this.datearray);

      // [date.getFullYear(), mnth, day].join("-");


      // var dateTime1 = moment(d1).format("YYYY-MM-DD");
      // var day1 = moment(d1).format('ddd');
      // var my_obj = {
      //     date: dateTime1,
      //     day: day1
      // };
      // date.push(my_obj);
    }
  }
  addressadd() {
    this.isaddressadd = true;
  }

  getproviders() {
    console.log(this.description);

    if (this.bookingdate) {
      this.cookie.set('date', this.bookingdate);
    } else {
      environment.swalalert('error', 'Please Enter Date')
      return 0;
    }
    if (this.timingid) {
      this.cookie.set('time_slot_id', this.timingid);
    } else {
      environment.swalalert('error', 'Please Enter Timing')
      return 0;
    }
    this.cookie.set('description', this.description);
    //this.cookie.set('date',this.bookingdate);

    //this.cookie.set('city',this.bookingcity);
    if (this.bookinglatitude || this.bookinglongitude || this.bookingcity) {
      this.cookie.set('lat', this.bookinglatitude);
      this.cookie.set('lon', this.bookinglongitude);
      this.cookie.set('city', this.bookingcity);
    } else {
      environment.swalalert('error', 'Please Select Address')
      return 0;
    }
    //  this.cookie.set('lat',this.bookinglatitude);
    //  this.cookie.set('lon',this.bookinglongitude);

    this.router.navigateByUrl('/providers')
  }

  // remove selected image and video
  removeSelectedImage(index: number) {
    this.selectedImages.splice(index, 1);
  }

  // read image url
  readURL(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let type = files[i].type.split("/")
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const url = e.target.result;
          console.log("event ", { file: file, url: url, type: type[0] })
          this.selectedImages.push({ file: file, url: url, type: type[0] });
        };
        reader.readAsDataURL(file);
      }
    }
  }

  uploadImageFunction(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {

      const uploadNextImage = (index: number) => {
        if (index < this.selectedImages.length) {
          const file = this.selectedImages[index].file;

          this.service.fileupload(file).subscribe(
            (res) => {
              console.log('res', res);
              if (res.body && res.body['error'] === 'true') {
                environment.swalalert('nodata', res.body['error_message']);
                reject('Image upload failed');
              } else {
                const ret = res.body;
                console.log("bid image", res.body['image']);
                const uploadedImageURL = ret['image'];
                this.uploadedImageURLs.push({ url: uploadedImageURL, type: this.selectedImages[index].type });

                uploadNextImage(index + 1);
              }
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
        } else {
          resolve(this.uploadedImageURLs);
        }
      };

      uploadNextImage(0);
    });
  }





  async demandbiddingproviders() {
    if (this.bookingdate) {
      this.cookie.set('date', this.bookingdate);
    } else {
      environment.swalalert('error', 'Please Enter Date')
      return 0;
    }
    if (this.timingid) {
      this.cookie.set('time_slot_id', this.timingid);
    } else {
      environment.swalalert('error', 'Please Enter Timing')
      return 0;
    }
    this.cookie.set('description', this.description);
    if (this.bookinglatitude || this.bookinglongitude || this.bookingcity) {
      this.cookie.set('lat', this.bookinglatitude);
      this.cookie.set('lon', this.bookinglongitude);
      this.cookie.set('city', this.bookingcity);
    } else {
      environment.swalalert('error', 'Please Select Address')
      return 0;
    }
    if (this.mins == 0) {
      environment.swalalert('error', 'Please Enter Biding Miute')
      return 0;
    }
    this.image_URL = await this.uploadImageFunction();
    console.log("this.image_URL", this.image_URL)
    var req = {
      "subcategory_id": this.cookie.get('sub_catagory_id'),
      "category_id": this.cookie.get('category_id'),
      "time_slot_id": this.timingid,
      "date": this.bookingdate,
      "user_id": this.cookie.get('userid'),
      "radius": "50000",
      "latitude": this.bookinglatitude,
      "longitude": this.bookinglongitude,
      "address_id": this.cookie.get('addressid'),
      "description": this.description ? this.description : "",
      "biding_time": (this.hours * 60) + this.mins,
      "biding_image": JSON.stringify(this.image_URL)
    }
    console.log("randon biding request", req)
    $('#biddingformModel').modal('hide');
    $('#ondemandbidingModal').modal('show');
    this.socket.emit('GetBidingRequest', req, res => {
    });
  }

  loadMultimedia(url) {
    console.log("loadMultimedia", url)
    window.open(url, '_blank');
  }


  getMarginTop() {
    return 10 + this.selectedImages.length * 20;
  }

  initAcks() {
    this.socket.on('random_request_accepted-' + this.cookie.get('userid'), res => {
      console.log('random_request_accepted', res);
      $('#ondemandModal').modal('hide');
      $('#ondemandacceptModal').modal('show');
    })
    this.socket.on('request_completed-' + this.cookie.get('userid'), res => {
      $('#ondemandModal').modal('hide');
      $('#ondemandcompletedModal').modal('show');
      console.log('request_completed', res);
    })
  }

  demandproviders() {
    this.initAcks()
    if (this.bookingdate) {
      this.cookie.set('date', this.bookingdate);
    } else {
      environment.swalalert('error', 'Please Enter Date')
      return 0;
    }
    if (this.timingid) {
      this.cookie.set('time_slot_id', this.timingid);
    } else {
      environment.swalalert('error', 'Please Enter Timing')
      return 0;
    }
    this.cookie.set('description', this.description);
    if (this.bookinglatitude || this.bookinglongitude || this.bookingcity) {
      this.cookie.set('lat', this.bookinglatitude);
      this.cookie.set('lon', this.bookinglongitude);
      this.cookie.set('city', this.bookingcity);
    } else {
      environment.swalalert('error', 'Please Select Address')
      return 0;
    }
    var req = {
      "subcategory_id": this.cookie.get('sub_catagory_id'),
      "category_id": this.cookie.get('category_id'),
      "time_slot_id": this.timingid,
      "date": this.bookingdate,
      "user_id": this.cookie.get('userid'),
      "radius": "50000",
      "latitude": this.bookinglatitude,
      "longitude": this.bookinglongitude,
      "address_id": this.cookie.get('addressid'),
      "description": this.description ? this.description : ""
    }
    console.log("randon request", req)
    $('#ondemandModal').modal('show');
    this.socket.emit('GetRandomRequest', req, res => {
    });
  }


  addresssave() {


    this.addressForm.patchValue({ 'latitude': this.latitude })
    this.addressForm.patchValue({ 'longitude': this.longitude })
    this.addressForm.patchValue({ 'address': this.address })
    this.service.addaddress(this.addressForm.value).subscribe(res => {
      if (res.body['error'] == 'false') {
        environment.swalalert('success', res.body['msg']).then(value => {
          $("[data-dismiss=modal]").trigger({ type: "click" });
        });
        this.isaddressadd = false;
      } else {
        environment.swalalert('error', res.body['error_message']);
      }
    })
    console.log('address', this.addressForm.value);


    this.userviewaddress();
  }
  viewaddress(timing) {
    console.log(timing);

    this.timingid = timing.id;
    this.listaddress.forEach(element => {
      element.spanactive = false
    });
    timing.spanactive = !timing.spanactive;
  }
  selectdate(date) {
    console.log(date);
    this.bookingdate = date.formatdate;
    this.datearray.forEach(element => {
      element.spanactive = false
    });
    date.spanactive = !date.spanactive;

    console.log(this.datearray);

  }
  selectaddress(address) {
    this.bookinglatitude = address.latitude;
    this.bookinglongitude = address.longitude;
    this.bookingcity = address.address_line_1;
    this.useraddress.forEach(element => {
      element.addressactive = false
    });
    address.addressactive = !address.addressactive;
    console.log('selectd address', address);
    this.cookie.set('addressid', address.id);
    console.log(this.useraddress);
  }
  addresstimings() {
    this.service.listaddress().subscribe((res) => {
      console.log(res);

      console.log(res.body['timeslots']);
      this.listaddress = res.body['timeslots'];
    });
  }

  userviewaddress() {
    this.service.useraddress().subscribe((res) => {
      console.log('addrtdfytxsguh', res.body['list_address']);
      this.useraddress = res.body['list_address'];
    });
  }

  //common modal functions end
}
