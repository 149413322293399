import { Component, ElementRef, OnInit ,ViewChild,NgZone} from '@angular/core';
import { Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as $ from 'src/assets/js/jquery.min.js';

import { MapsAPILoader } from '@agm/core';
import { ProvidersService } from './providers.service';
import { CommonService } from '../../common.service';


declare let $:any;
@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css']
})
export class ProvidersComponent implements OnInit {
  isLogged: boolean = false;  
   isaddressadd=false;
  addressForm:FormGroup;
  latitude: number;
  longitude: number 
  zoom: number=8;
  address: any;
  private geoCoder;
  coordinates = {}
  @ViewChild('search')
  public searchElementRef: ElementRef;
 subcategoryid:any;
 timeslotid:any;
 date:any;
 city:any;
 lat:any;
 lon:any;
 providerlist:any;
 currentRate = 3.14;
  
  constructor(private router: Router,
    private cookie: CookieService,
    private formBuilder : FormBuilder,
    private service : ProvidersService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private commonservice:CommonService) { }

  ngOnInit() {
 //map

    if (this.cookie.get('Token')) {
      this.isLogged = true;
      
  
     // common invoice modal end
    }
    this.subcategoryid=this.cookie.get('sub_catagory_id')
   this.timeslotid= this.cookie.get('time_slot_id')
this.date=this.cookie.get('date');
this.city=this.cookie.get('city')
this.lat=this.cookie.get('lat')
this.lon=this.cookie.get('lon')
const bookingdata={service_sub_category_id:this.subcategoryid,time_slot_id:this.timeslotid,date:this.date,city:this.city,lat:this.lat,lon:this.lon}
console.log(bookingdata);

this.service.listprovider(bookingdata).subscribe((res) =>{

  if(res.body['error'] === 'false')
  {
  
    this.providerlist = res.body['all_providers'];
   
    
    console.log(this.providerlist.length);
    this.providerlist.map(res=>{
     res.avg_rating= res.avg_rating.toFixed(2);
    })
    this.providerlist.map((res,i)=>{

      res.label=i;
      res.draggable=false;
      res.visible=false;
      res.opacity=0.7
      
    })
    console.log(this.providerlist);

   this.latitude= this.providerlist[0].latitude;
   this.longitude= this.providerlist[0].longitude;
    console.log(this.latitude);
    
    //  this.imagefilename = this.result.image;
    
     

 }else{
   this.providerlist= res.body['all_providers'];
   console.log(this.providerlist.length);
   
 }
});
  }


  providerdetail(data){
    console.log('dosdn',data);
    
localStorage.setItem('providerdata',JSON.stringify(data));
  }
 
}
