import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ElementRef, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AccountService } from './account.service';
import { AccountnavbarComponent } from '../accountnavbar/accountnavbar.component';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Account } from './account.model';
//import * as $ from 'src/assets/js/jquery.min.js';
import { BehaviorSubject, from } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { AccountnavbarService } from '../accountnavbar/accountnavbar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../common.service';



declare let $: any;

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, OnDestroy {
  @ViewChild('img') img: ElementRef;

  imgURL: any;
  imageFile: File[];
  Logged: Boolean = false;
  editbutton: Boolean = false;
  // first_name: String;
  email: String;
  result: any = {};
  viewresult: any;
  modelview: any = {};
  firstname: String;
  lastname: String;
  phonenumber: number;
  editForm: FormGroup;
  addressForm: FormGroup;
  accountModel: Account;
  file: any;
  //  imagefilename: string | ArrayBuffer;
  imagefilename: any;
  imageURL: any;
  file1: any;
  addressresult: any;
  modaltitle = '';
  //mapvars
  latitude: number = 11.664325;
  longitude: number = 78.1460142;
  zoom: number;
  address: any;
  private geoCoder;
  coordinates = {}
  @ViewChild('search')
  public searchElementRef: ElementRef;

  addressadding = false;
  tabclasses: any = [false, true, false, false, false, false, false];
  ishidecancel: boolean = false;
  //
  //modal declarations
  biduserslist: any
  invoice: any;
  isLogged: boolean = false;
  uploadForm: FormGroup;
  invoicepdf: any;
  profileimagename: any;
  fabButtons = [
    { icon: 'message', route: '/chat' }
  ];
  buttons = [];
  fabTogglerState = 'inactive';
  //  private open:BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private cookie: CookieService,
    private service: AccountService,
    private accountnavservice: AccountnavbarService,
    private profilenavbar: AccountnavbarComponent,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private commonservice: CommonService
  ) {

    this.editForm = this.formBuilder.group({
      'first_name': ['', [Validators.required, Validators.minLength(1)]],
      'last_name': ['', [Validators.required, Validators.minLength(1)]],
      'mobile': ['', [Validators.required, Validators.minLength(1)]],
      'email': ['', [Validators.required, Validators.minLength(1)]],
      'image': ['', [Validators.required, Validators.minLength(1)]]
    });

    this.uploadForm = this.formBuilder.group({
      'file': ['']
    })
    this.addressForm = this.formBuilder.group({
      'id': [''],
      'title': ['', [Validators.required, Validators.minLength(1)]],
      'doorno': ['', [Validators.required, Validators.minLength(1)]],
      'landmark': ['', [Validators.required, Validators.minLength(1)]],
      'address': ['', [Validators.required, Validators.minLength(1)]],
      'latitude': [],
      'longitude': []
    });
  }
  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }
  fabselect(data) {

    this.cookie.set('receivername', data.providername);
    this.cookie.set('receiverid', data.provider_id);
    this.cookie.set('bookingid', data.id)
    $('#myModal').modal('hide');

  }
  ngOnInit() {
    if (this.cookie.get('Token')) {
      this.isLogged = true;
    }
    //map
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);


      console.log(autocomplete);

      autocomplete.addListener("place_changed", () => {


        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log(place);
          this.address = place.formatted_address

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });

    //modal

    //


    if (this.cookie.get('Token')) {


      this.Logged = true;

      this.profilenavbar.getUserDetails();


    }
    console.log(this.profilenavbar);
    // View Profile

    this.accountnavservice.accountnav.subscribe(res => {
      console.log('from account menu', res);

      if (res == 'address') {
        console.log('ther');
        this.tabclasses.forEach(element => {
          element = false
        });
        this.tabclasses[6] = true
        //
        this.tabclasses[0] = false
        this.tabclasses[1] = false
        //
        this.listaddress();
      } else if (res == '0') {
        this.viewbookings();
      } else if (res == 'profile') {
        this.tabclasses.forEach(element => {
          element = false
        });
        this.tabclasses[0] = true
        //
        this.tabclasses[6] = false
        this.tabclasses[1] = false
        //
        this.viewprofile()
      }
    })
    this.viewprofile();


    // End Viewprofile


  }
  output(log) {
    console.log(log);
  }
  modalclose() {

    this.ishidecancel = false;
    console.log('i am called');
    this.modelview = {}
    console.log('afterclose', this.modelview);


  }
  ngOnDestroy() {
    this.accountnavservice.accountnav.next('0');
  }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {


      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;


        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  addresssave() {
    console.log('address', this.addressForm.value);


    this.addressForm.patchValue({ 'latitude': this.latitude })
    this.addressForm.patchValue({ 'longitude': this.longitude })
    if (this.addressForm.value.id) {
      console.log('edit');
      if (this.address) {


        this.addressForm.patchValue({ 'address': this.address })
      }
      this.service.editaddress(this.addressForm.value).subscribe(res => {
        console.log(this.address);


        if (res.body['error'] == 'false') {
          this.listaddress();
          environment.swalalert('success', res.body['msg']).then(value => {
            $("[data-dismiss=modal]").trigger({ type: "click" });
          });
          this.addressadding = false;
        } else {
          environment.swalalert('error', res.body['error_message']);
        }
      })

    } else {
      this.addressForm.patchValue({ 'address': this.address })
      this.service.addaddress(this.addressForm.value).subscribe(res => {
        if (res.body['error'] == 'false') {
          this.listaddress();
          environment.swalalert('success', res.body['msg']).then(value => {
            $("[data-dismiss=modal]").trigger({ type: "click" });
          });
          this.addressadding = false;
        } else {
          environment.swalalert('error', res.body['error_message']);
        }
      })
      console.log('address', this.addressForm.value);

    }
    // this.listaddress();
  }

  addaddress() {

    this.addressadding = true;


    this.addressForm.reset();
    // this.addressForm.controls['latitude'].setValue(12);
    // this.addressForm.controls['longitude'].setValue(12);
    this.modaltitle = "Add Address"



  }
  reset() {
    this.file1 = undefined;
  }



  // image preview
  readURL(event) {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      this.file1 = event.target.files[0];
      console.log(event.target.files[0].name);
      this.profileimagename = event.target.files[0].name
      const reader = new FileReader();
      reader.onload = e => this.imagefilename = reader.result;
      reader.readAsDataURL(this.file);
      // console.log(reader.readAsBinaryString(this.file));
      // reader.readAsBinaryString(this.file);

    }
  }



  uploadImageFunction() {
    return new Promise<any>((resolve, reject) => {
      console.log('this.file', this.file)
      const blob = new Blob([this.file])
      console.log(blob);




      this.service.fileupload(this.file).subscribe((res) => {
        console.log('res', res)
        if (res.body['error'] === 'true') {
          environment.swalalert('nodata', res.body['error_message']);
        } else {
          var ret = res.body;
          resolve(ret['image']);
        }
      },
        (err) => {
          console.log(err);
          reject(err);
        })
    })
  }
  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  // end image preview
  cleanDate(d) {
    var date = new Date(+d.replace(/\/Date\((\d+)\)\//, '$1'))
    var validDate = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes()
    return validDate
  }

  loadMultimedia(url) {
    console.log("loadMultimedia", url)
    window.open(url, '_blank');
  }

  detailView(data) {
    console.log(this.modelview);

    this.modelview = data;
    this.invoicepdf = data.invoicelink
    this.modelview.biding_image = JSON.parse(data.biding_image)
    this.modelview.biding_end_timestamp = this.cleanDate(data.biding_time_end_timestamp)
    console.log(this.invoicepdf);

    if (data.startjob_timestamp || data.CancelledbyProvider_time || data.CancelledbyUser_time || data.Rejected_time) {
      console.log('yes');
      this.ishidecancel = true;
    }

    console.log(this.modelview);

    //console.log(date);



    //  var hours = date.getHours();
    // // var minutes = date.getMinutes();
    //  var ampm = hours >= 12 ? 'pm' : 'am';

    //hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    // console.log(ampm);

    // minutes = minutes < 10 ? '0'+minutes : minutes;
    // var strTime = hours + ':' + minutes + ' ' + ampm;
    // console.log('after',ampm);
    // return strTime;





  }

  loadBidList(id) {
    const biddersdata: any = { id: id }
    this.service.getbidslist(biddersdata).subscribe(res => {
      if (res.body['error'] == 'false') {
        console.log("res.body", res.body)
        this.biduserslist = res.body['bided_providers']
      } else {
        environment.swalalert('error', res.body['error_message']);
      }
    })

  }

  acceptBid(id, provider_id) {
    const biddersacceptdata: any = { id: id, provider_id: provider_id }
    this.service.acceptbid(biddersacceptdata).subscribe(res => {
      if (res.body['error'] == 'false') {
        environment.swalalert('success', res.body['error_message']);
        window.location.reload()
      } else {
        environment.swalalert('error', res.body['error_message']);
      }
    })
  }

  ampm(date) {

    var hours = date.getUTCHours();

    var ampm = hours >= 12 ? 'am' : 'pm';

    return ampm;
  }
  cancelbooking(data) {
    const canceldata: any = { id: data }
    this.service.cancelbooking(canceldata).subscribe(res => {
      if (res.body['error'] == 'false') {
        environment.swalalert('success', res.body['msg']).then(value => {
          $("[data-dismiss=modal]").trigger({ type: "click" });
          this.viewbookings()
        });
      } else {
        environment.swalalert('error', res.body['error_message']);
      }
    })
  }
  getViewBooking() { }

  // View Profile
  viewprofile() {
    this.service.viewprofile().subscribe((res) => {

      if (res.body['error'] === 'false') {
        this.result = res.body['user_details'];
        this.imagefilename = this.result.image;
        var filename = this.result.image.substring(this.result.image.lastIndexOf('/') + 1);
        this.profileimagename = filename;



      }
    });
  }
  // End Viewprofile
  viewbookings() {
    // ViewBookings
    this.service.view_bookings().subscribe((res) => {
      console.log('res.body', res.body)
      //  environment.swalalert('loader','Please Wait While the Bookings Loads')
      if (res.body['error'] == 'false') {
        this.viewresult = res.body['all_bookings'];


        this.viewresult.map(res => {
          if (res.created_at) {
            res.created_at = res.created_at + this.ampm(new Date(res.created_at))
            res.chatenable = false;

          }
          if (res.Accepted_time) {
            res.Accepted_time = res.Accepted_time + this.ampm(new Date(res.Accepted_time))
            res.chatenable = true;
          }
          if (res.Rejected_time) {
            res.Rejected_time = res.Rejected_time + this.ampm(new Date(res.Rejected_time))
            res.chatenable = false;
          }
          if (res.CancelledbyUser_time) {
            res.CancelledbyUser_time = res.CancelledbyUser_time + this.ampm(new Date(res.CancelledbyUser_time))
            res.chatenable = false;
          }
          if (res.CancelledbyProvider_time) {
            res.CancelledbyProvider_time = res.CancelledbyProvider_time + this.ampm(new Date(res.CancelledbyProvider_time))
            res.chatenable = false;
          }
          if (res.StarttoCustomerPlace_time) {
            res.StarttoCustomerPlace_time = res.StarttoCustomerPlace_time + this.ampm(new Date(res.StarttoCustomerPlace_time))
            res.chatenable = true;
          }
          if (res.startjob_timestamp) {
            res.startjob_timestamp = res.startjob_timestamp + this.ampm(new Date(res.startjob_timestamp))
            res.chatenable = true;
          }
          if (res.endjob_timestamp) {
            res.endjob_timestamp = res.endjob_timestamp + this.ampm(new Date(res.endjob_timestamp))
            res.chatenable = false;
          }
        })
        console.log(this.viewresult);
      }
    })
    // EndViewBooking
  }
  editaddress(data) {
    this.addressadding = true;
    this.modaltitle = "Edit Address"
    console.log(data.title);
    this.addressForm.controls['id'].setValue(data.id);
    this.addressForm.controls['title'].setValue(data.title);
    this.addressForm.controls['doorno'].setValue(data.doorno);
    this.addressForm.controls['landmark'].setValue(data.landmark);
    this.addressForm.controls['address'].setValue(data.address_line_1);

    //this.addressForm.value=data.title;
  }

  deleteaddress(id) {
    const data = { id: id }
    this.service.deleteaddress(data).subscribe(res => {

      if (res.body['error'] == 'false') {
        environment.swalalert('success', res.body['msg']).then(value => {
          this.listaddress();
        });
      } else {
        environment.swalalert('error', res.body['error_message']);
      }

    })

  }
  listaddress() {
    this.service.listaddress().subscribe((res) => {
      console.log('res.body', res.body)
      //  environment.swalalert('loader','Please Wait While the Bookings Loads')
      if (res.body['error'] == 'false') {
        this.addressresult = res.body['list_address'];
        console.log(this.addressresult);
      }
    })
  }
  clickedit(data) {

    this.service.viewprofile().subscribe((res) => {
      console.log(res.body);
      if (res.body['error'] === 'false') {
        this.imagefilename = this.result.image;
        this.result = res.body['user_details'];
        this.editForm = this.formBuilder.group({

          'first_name': [this.result.first_name, [Validators.required, Validators.minLength(1)]],
          'last_name': [this.result.last_name, [Validators.required, Validators.minLength(1)]],
          'mobile': [this.result.mobile, [Validators.required, Validators.minLength(1)]],
          'email': [this.result.email, [Validators.required, Validators.minLength(1)]],

        });
        console.log(this.editForm);
      }
    });
    // data == 'view' ? this.editbutton = true : this.editbutton = false;
    if (data == 'view') {
      this.editbutton = true;
    } else {

      this.editbutton = false;
    }
  }
  goToLink(link) {
    console.log('dsd', link);
    const url: string = "" + link + "";
    console.log('asd', link);
    encodeURIComponent(JSON.stringify(link))
    window.open(encodeURI(JSON.stringify(link)), '_blank');

  }
  // update profile
  async update() {
    console.log(this.file);

    if (this.file1 != undefined) {
      console.log('new image')
      this.imageURL = await this.uploadImageFunction();
    } else {
      console.log('old image')
      // this.imageURL = await this.uploadImageFunction();
      this.imageURL = this.imagefilename
    }
    if (this.editForm.valid) {
      var sF = this.editForm.value;
      this.accountModel = new Account(
        sF.first_name,
        sF.last_name,
        sF.mobile,
        sF.image
      );
      this.accountModel.image = this.imageURL

      console.log(this.accountModel, this.imageURL);

      this.service.updateProfile(this.accountModel).subscribe((res) => {
        if (res.body['error'] == 'false') {
          environment.swalalert('success', res.body['msg']).then(value => {
            if (value) {
              this.editbutton = false;
              this.cookie.set('first_name', sF.first_name);
              this.cookie.set('last_name', sF.last_name);
              this.cookie.set('image', this.imageURL);
              // this.router.navigate(['myaccount']);
              // this.viewprofile();
              window.location.reload();
            }
          });
        } else {
          environment.swalalert('error', res.body['error_message']);
        }
      });
    }
    else {
      environment.swalalert('error', 'Validation Required');
    }

  }
  // End update profile
  logout() {
    environment.swalalert('warning', ['msg']).then(value => {
      if (value) {
        this.cookie.deleteAll();
        this.router.navigate([''])

        clearInterval(this.commonservice.interval);
      }
    });


  }
  back() {
    this.addressadding = false

  }








  // modalclose(){
  //   $(document).ready(function(){


  //       $('#myinvoiceModal').modal('hide');

  // });

  // //  $('#myinvoiceModal').modal('hide');

  // }
  //common modal functions end
}
