import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../common.service';

import { environment } from 'src/environments/environment';

declare let $: any;
@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})
export class LandingpageComponent implements OnInit, AfterViewInit {

  isLogged: boolean = false;

  constructor(private router: Router,
    private cookie: CookieService,
    private commonservice: CommonService,
    private location: Location) { }


  ngOnInit() {
    // location.reload() 

    if (this.cookie.get('Token')) {
      this.isLogged = true;
      // window.location.reload();



    }


  }
  ngAfterViewInit() {

  }

}
