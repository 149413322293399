import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AccountnavbarService {
  url = environment.Url;
  httpheaders = new HttpHeaders({
 
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer '+ this.cookie.get('Token').split('|')[0]
   
  });

  public accountnav = new BehaviorSubject<any>('0');
  constructor(private http:HttpClient,
    private cookie: CookieService,
     ) { }

    
    viewprofile(){
      return this.http.get(`${this.url}viewprofile`,{
        headers: this.httpheaders,
            observe: 'response'
      });
    }
}
