import { Component, ElementRef, OnInit ,ViewChild,NgZone, OnDestroy} from '@angular/core';
import { Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';


import { MapsAPILoader } from '@agm/core';
//import { ProvidersService } from './providers.service';
import { CommonService } from '../common.service';
import { ChatService } from './chat.service';
import { Socket } from 'ngx-socket-io';
import 'rxjs/add/operator/map'
import { map } from 'rxjs/operators';
declare let $:any;
@Component({
  selector: 'app-chatlist',

  templateUrl: './chatlist.component.html',
  styleUrls: ['./chatlist.component.css'],

})
export class ChatlistComponent implements OnInit ,OnDestroy{
  isLogged: boolean = false;  
  
  newMessage:any;
  currentUser:any={id:3}
  messages:any;
  message:any={};
  enteredmessage:any;
//   =[ {user :{id:1},text:'hi hello how',senderType:'provider'},{user :{id:3},text:'hi hello',senderType:'user'}]
  channelList:any =[]
  receiverimage:any='';
  senderimage:any='';
  interval:any
  

  constructor(
    private socket: Socket,
    private router: Router,
    private cookie: CookieService,
    private formBuilder : FormBuilder,
   private service : ChatService,
    private commonservice:CommonService) { }
    onScrollDown(){
      console.log('i am scrolled down');
    }
    onScrollUp(){
      console.log('i am scrolled up');
      
    } 
  ngOnInit() {

  
    if (this.cookie.get('Token')) {
      this.isLogged = true;
   const data=  {'userid':parseInt(this.cookie.get('userid'))}
  //this.interval=  setInterval(() =>{
   this.socket.emit('connect',data,res=>{

    console.log('after connect',res);
    
   });
   this.socket.emit('get_user_online',data,res=>{

    console.log('after online',res);
    
   });
   var receivername=this.cookie.get('receivername')
  
   this.channelList.push({'providername':receivername})
  // this.interval=  setInterval(() =>{
  this.getmessage();
  // },5000);
  this.message.sender_id=this.cookie.get('userid')
  this.message.reciever_id=this.cookie.get('receiverid')
  this.message.booking_id=this.cookie.get('bookingid')
//},5000);
 
    }
  }

  sendMessage() {
  
   this.message.content_type='text';
    this.message.Time=new Date().getTime();
    this.message.content=this.enteredmessage;
    this.message.message_id=this.uuid();
console.log(this.message);

    
     this.socket.emit('sendmessagefromuser', this.message,res=>{
  
      console.log(res);
      
     });
  console.log('entered',this.message);
  
     this.enteredmessage='';
     this.getmessage();
     console.log("send",this.message);
   }
   formattime(data){
    const d = new Date( parseInt(data) );
    var hours=d.getHours();
    var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
         if(hours==0){
hours=12
}
  return    hours + ":" + d.getMinutes() + "" + ampm;
   }
  getmessage(){

    this.socket.on('recievemessage',res=>{
      console.log(res);
      res.senderType='provider';
    
      this.senderimage=this.senderimage;
  
       
      res.time=this.formattime(res.Time)
      if(!this.messages.includes(res)){
        console.log('there');
        this.messages.push(res)
      }
      // this.messages.push(res)
  
    })
   this.socket.fromEvent("recievemessage").map( data => console.log(data));
    const data={ senderID :this.cookie.get('userid'),receiverID:this.cookie.get('receiverid') ,senderType:'users',  receiverType:'provider',  page:1}
    console.log(this.cookie.get('receivername'));
   
  
    this.service.chatlist(data).subscribe((res) =>{
 
         if(res.body['error'] == 'false')
         {
           console.log(res.body['receiver_profilePic']);
          this.receiverimage=res.body['receiver_profilePic'];
 
          this.senderimage=res.body['sender_profilePic'];
         this.messages=res.body['results'];   
         this.messages.map(res=>{ 
          res.time=this.formattime(res.Time)
         
        })
     
        
        }else{
         this.messages=res.body['results'];   
         
          
        }
       });
  }
   uuid()
{
   var chars = '0123456789abcdef'.split('');

   var uuid = [], rnd = Math.random, r;
   uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
   uuid[14] = '4'; // version 4

   for (var i = 0; i < 36; i++)
   {
      if (!uuid[i])
      {
         r = 0 | rnd()*16;

         uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r & 0xf];
      }
   }

   return uuid.join('');
}
  
 
  ngOnDestroy(){
   clearInterval(this.interval)
  }
}
