import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { NotfoundpageComponent } from './notfoundpage/notfoundpage.component';
import { FooterComponent } from './landingpage/footer/footer.component';
import { NavbarComponent } from './landingpage/navbar/navbar.component';
import { HomepageComponent } from './landingpage/homepage/homepage.component';
import { MultiloginComponent } from './multilogin/multilogin.component';
import { ProviderComponent } from './provider/provider.component';
import { ProviderloginComponent } from './provider/providerlogin/providerlogin.component';
import { UserComponent } from './user/user.component';
import { UserloginComponent } from './user/userlogin/userlogin.component';
import { ProvidersignupComponent } from './provider/providersignup/providersignup.component';
import { ProviderregisterComponent } from './provider/providersignup/providerregister/providerregister.component';
import { UsersignupComponent } from './user/usersignup/usersignup.component';
import { UserregisterComponent } from './user/usersignup/userregister/userregister.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { NavbartwoComponent } from './landingpage/navbartwo/navbartwo.component';
import { CentersearchComponent } from './search/centersearch/centersearch.component';
import { ViewprofileComponent } from './viewprofile/viewprofile.component';
import { ProfilecenterComponent } from './viewprofile/profilecenter/profilecenter.component';
import { AboutUsComponent } from './landingpage/about-us/about-us.component';
import { NgProgressModule } from 'ngx-progressbar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import { AccountnavbarComponent } from './landingpage/accountnavbar/accountnavbar.component';

import { AccountComponent } from './landingpage/account/account.component';
import { GuardComponent } from './guard/guard.component';
import { HomePageNewComponent } from './landingpage/home-page-new/home-page-new.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ScheduleComponent } from './landingpage/schedule/schedule.component';
import { AgmCoreModule } from '@agm/core';
import { ProvidersComponent } from './landingpage/providers/providers.component';
import { ProviderviewComponent } from './landingpage/providerview/providerview.component';
import { ForgotpasswordComponent } from './user/forgotpassword/forgotpassword.component';
import { VerifyotpComponent } from './user/verifyotp/verifyotp.component';
import { ResetpasswordComponent } from './user/resetpassword/resetpassword.component';
import { CommonService } from './common.service';
import { AuthinterceptorService } from './authinterceptor.service';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './messaging.service';

import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';

import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChatlistComponent } from './chatlist/chatlist.component';
import { MatButtonModule } from '@angular/material/button';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PaymentmodalComponent } from './paymentmodal/paymentmodal.component';
import { NgxStripeModule } from 'ngx-stripe';


const socketconfig: SocketIoConfig = { url: 'https://homesrunner.com/', options: {} };
let config = new AuthServiceConfig([
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider("960221500626-s17dfn574cbf8f8ktdq710kqugr6g05d.apps.googleusercontent.com")
    },
    {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("360230925363349")
    }
]);

export function provideConfig() {
    return config;
}




@NgModule({
    declarations: [
        AppComponent,
        LandingpageComponent,
        NotfoundpageComponent,
        FooterComponent,
        NavbarComponent,

        HomepageComponent,
        MultiloginComponent,
        ProviderComponent,
        ProviderloginComponent,
        UserComponent,
        UserloginComponent,
        ProvidersignupComponent,
        ProviderregisterComponent,
        UsersignupComponent,
        UserregisterComponent,
        SearchComponent,
        NavbartwoComponent,
        CentersearchComponent,
        ViewprofileComponent,
        ProfilecenterComponent,
        AboutUsComponent,
        AccountnavbarComponent,
        AccountComponent,
        GuardComponent,
        HomePageNewComponent,
        ScheduleComponent,
        ProvidersComponent,
        ProviderviewComponent,
        ForgotpasswordComponent,
        VerifyotpComponent,
        ResetpasswordComponent,
        ChatlistComponent,
        PaymentmodalComponent,



    ],
    imports: [
        Ng2SearchPipeModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgProgressModule,
        HttpClientModule,
        HttpModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBNpL1fke2skl8SsjZRnwYiUQ4ArNByxIA',
            libraries: ['geometry', 'places']
        }),
        NgbModule.forRoot(),
        SocialLoginModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        MatButtonModule,
        SocketIoModule.forRoot(socketconfig),
        InfiniteScrollModule,
        NgxStripeModule.forRoot("pk_live_51LrOvgDfoC3PHieN4cEWhTp8iR7VB9E0vPqbPnWALnXYpNSFqLjGIIk1XGMlpKLLVa9IKbbx7E7sCP0QLgmodFZP00vvHeqJk3")

    ],
    providers: [
        CookieService,
        AccountnavbarComponent,

        CommonService,
        MessagingService, AsyncPipe,
        { provide: HTTP_INTERCEPTORS, useClass: AuthinterceptorService, multi: true },
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
