import { Component, ElementRef, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as $ from 'src/assets/js/jquery.min.js';

import { MapsAPILoader } from '@agm/core';
import { ProvidersService } from './../providers/providers.service';
import { AccountnavbarService } from '../accountnavbar/accountnavbar.service';
import { CommonService } from '../../common.service';


declare let $: any;
@Component({
  selector: 'app-providerview',
  templateUrl: './providerview.component.html',
  styleUrls: ['./providerview.component.css']
})
export class ProviderviewComponent implements OnInit {

  isLogged: boolean = false;
  providerdetail: any;
  subcategoryid: any;
  timeslotid: any;
  date: any;
  addressid: any;
  remainingservices: any;
  remainingreviews: any;
  isservicereadmore: boolean = false;
  isservicereadless: boolean = false;
  isreviewreadmore: boolean = false;
  isreviewreadless: boolean = false;
  addresslatitude: any;
  addresslongitude: any;
  label: any = ''
  draggable = false
  zoom: number = 8;

  constructor(private router: Router,
    private cookie: CookieService,
    private formBuilder: FormBuilder,
    private service: ProvidersService,
    private ngZone: NgZone,
    private navbarservice: AccountnavbarService,
    private commonservice: CommonService) { }

  ngOnInit() {
    this.isservicereadmore = true;
    this.isreviewreadmore = true;
    if (this.cookie.get('Token')) {
      this.isLogged = true;


    }
    this.subcategoryid = this.cookie.get('sub_catagory_id')
    this.timeslotid = this.cookie.get('time_slot_id')
    this.date = this.cookie.get('date');
    this.addressid = this.cookie.get('addressid');
    this.addresslatitude = parseInt(this.cookie.get('lat'));
    this.addresslongitude = parseInt(this.cookie.get('lon'));
    this.providerdetail = JSON.parse(localStorage.getItem('providerdata'))
    console.log('before splice', this.providerdetail.provider_services);
    //this.remainingservices=this.providerdetail.provider_services


    this.remainingservices = this.providerdetail.provider_services.splice(3);
    this.remainingreviews = this.providerdetail.reviews.splice(3)
    console.log('after splice', this.providerdetail.provider_services);
    console.log('', this.remainingservices);
    console.log(this.addresslatitude, this.addresslongitude);

  }
  bookprovider() {
    const data = { service_sub_category_id: this.subcategoryid, time_slot_id: this.timeslotid, date: this.date, provider_id: this.providerdetail.id, address_id: this.addressid }
    console.log(data);
    this.service.bookprovider(data).subscribe(res => {
      console.log("new booking result", res)
      if (res.body['error'] == 'false') {
        environment.swalalert('success', res.body['msg']).then(value => {

          this.router.navigateByUrl('/myaccount')
          // this.navbarservice.accountnav.next('')
        })


      } else {
        environment.swalalert('error', res.body['error_message']);
      }
    })


  }
  readless() {
    // this.isservicereadless=true
    this.isservicereadmore = true;
    this.isservicereadless = false;
    this.remainingservices = this.providerdetail.provider_services.splice(3)
    console.log('after readless', this.providerdetail.provider_services);
  }
  readmore() {
    this.isservicereadmore = false;
    this.isservicereadless = true;
    this.remainingservices.map(res => {
      this.providerdetail.provider_services.push(res)
    })
    console.log('after readmor', this.providerdetail.provider_services);

  }
  readmorereview() {
    this.isreviewreadmore = false;
    this.isreviewreadless = true;
    this.remainingreviews.map(res => {
      this.providerdetail.reviews.push(res)
    })
    console.log('after readmor', this.providerdetail.provider_services);
  }
  readlessreview() {
    this.isreviewreadmore = true;
    this.isreviewreadless = false;
    this.remainingreviews = this.providerdetail.reviews.splice(3)
    console.log('after readless', this.providerdetail.provider_services);
  }
  mapview() {

  }

}
