import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute,RouterLinkActive } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ProviderloginService } from './providerlogin.service';
import { Providerlogin } from './providerlogin.model'
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { reject } from 'q';
import { from } from 'rxjs';

@Component({
  selector: 'app-providerlogin',
  templateUrl: './providerlogin.component.html',
  styleUrls: ['./providerlogin.component.css']
})
export class ProviderloginComponent implements OnInit {
 
 
  providerLoginForm: FormGroup;
  Providerloginmodel: any;
  Providerlogin: any;
  ProviderloginService: any;
  constructor(
    private formBuilder : FormBuilder,
    private router : Router,
    private route : ActivatedRoute,
    private service : ProviderloginService,
    private cookie: CookieService)
            {   
              this.providerLoginForm = this.formBuilder.group({
                'email' : ['', [Validators.required,Validators.minLength(1),Validators.email]],
                'password' : ['', [Validators.required,Validators.minLength(1)]]
              });

             }

  ngOnInit() {  }
      random() {
        // set the length of the string
        var stringLength = 10;
        
        var stringArray = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','!','?'];
            var rndString = "";
          
            for (var i = 0; i < stringLength; i++) {
                var rndNum = Math.ceil(Math.random() * stringArray.length) - 1;
                rndString = rndString + stringArray[rndNum];
            };
            return rndString;
        }
        providerlogin() {
          console.log(this.providerLoginForm.controls);
          if(this.providerLoginForm.valid)
          {
            var login = this.providerLoginForm.value;
            this.Providerloginmodel = new Providerlogin(
              login.email,
              login.password
            );

            var data : any = {
              email: this.Providerloginmodel.email,
              password: this.Providerloginmodel.password,
              user_type: "Provider"
            };

            this.service.provider_login_auth(data).subscribe((result) =>{
              console.log(result.body);
              if(result.body['error'] === 'false')
              {
                this.cookie.set('Token', result.body['access_token'] + '|' + this.random())
                    
                environment.swalalert('success',result.body['msg']).then(value => {
                  if(value){
                    this.router.navigate(['home'])
                  }
                });
              }else {
                environment.swalalert('error','Invalide');
                }
            },
            (error) => {
              console.error(error);
              reject(error);
                }
            );
          }else{
            environment.swalalert('error','Validation Required');  
          }
         }

         email(){}

}
