import { Component, ElementRef, OnInit, ViewChild, NgZone, OnDestroy, } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';

//import * as $ from 'src/assets/js/jquery.min.js';
import {
  StripeService,
  ElementOptions,
  ElementsOptions,
  StripeCardComponent
} from 'ngx-stripe';

import { MapsAPILoader } from '@agm/core';
import { CommonService } from '../common.service';
import { paymentmodeltoken } from './paymentmodal.model';
import { CardDetailViewModel } from './paymentcardlist.model';






declare let $: any;
@Component({
  selector: 'app-paymentmodal',
  templateUrl: './paymentmodal.component.html',
  styleUrls: ['./paymentmodal.component.css'],


})
export class PaymentmodalComponent implements OnInit, OnDestroy {

  selected = false;
  onSelectCard() {
    this.selected = !this.selected;
  }

  paymentForm: FormGroup;
  stripeCardValid: boolean = false;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
  //modal declarations
  modelview: any = {}
  paymentToken: any;
  cardId: any;
  //activepaymethod=[true,false]
  iscash: boolean = false;
  iscard: boolean = false;


  couponcode: any;
  paymenttypes: any = []
  handler: any = null;
  selectedcardid: any;
  waitingforconfirmation: boolean = false;
  jobimage: any = {};
  feedback: any;
  rating: any;
  source: any;
  pages: any;
  page: number = 1;

  cardtoken: paymentmodeltoken;
  cardmodel: CardDetailViewModel;
  iscouponapplied: boolean = false;
  invoice: any;
  thankstext: boolean = false;
  result: CardDetailViewModel[];

  isLogged: boolean = false;


  options: ElementOptions = {
    hidePostalCode: true,
    style: {

      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 300,
        fontFamily: '"Times New Roman", Times, serif',
        fontSize: '18px',

        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  get validForm() {
    return this.paymentForm.valid && this.stripeCardValid;
  }

  elementsOptions: ElementsOptions = {
    locale: 'en'

  };



  //modal declarations end
  constructor(private router: Router,
    private cookie: CookieService,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private stripeService: StripeService,
    private commonservice: CommonService) { }
  private selection: string
  ngOnInit() {

    this.getCarddetails()
    // debugger
    if (this.cookie.get('Token')) {
      this.isLogged = false;
    }

    this.paymentForm = this.fb.group({
      name: ['', [Validators.required]]
    });

    this.paymentToken = this.fb.group({
      source: ['', [Validators.required]]
    });




    clearInterval(this.commonservice.interval);
    // common invoice modal 
    this.commonservice.interval = setInterval(() => {






      this.listpaymentmethod()

      // debugger
      this.commonservice.app_settings().subscribe(res => {
        if (res.body['error'] == 'false') {
          // console.log(res['body']['status'].length==0);

          // console.log(res['body']['all_bookings']);
          //  console.log(res['body']['status'][0].status);

          // res['body']['all_bookings'].map(res=>{
          console.log('i am from payment modal component');

          if (res['body']['status'].length > 0) {
            if (res['body']['status'][0].status == "Completedjob") {
              // console.log(res.id);
              this.modelview = res['body']['status'][0];
              $(document).ready(function() {
                $("#myinvoiceModal").modal();
              });
              //  $('#myinvoiceModal').modal({backdrop: 'static', keyboard: false}) 
            } else if (res['body']['status'][0].status == "Waitingforpaymentconfirmation") {
              // console.log(res.id);
              this.modelview = res['body']['status'][0];
              $(document).ready(function() {
                $("#myinvoiceModal").modal();
              });
              //  $('#myinvoiceModal').modal({backdrop: 'static', keyboard: false}) 
            } else if (res['body']['status'][0].status == 'Reviewpending') {
              this.modelview = res['body']['status'][0];
              this.startjobendjobdetails()

              $(document).ready(function() {
                $("#myinvoiceModal").modal();
              });
              //   $('#myinvoiceModal').modal({backdrop: 'static', keyboard: false}) 
            } else if (this.thankstext = true) {
              $(document).ready(function() {
                $("#myinvoiceModal").modal();
              });
            } else if (res['body']['status'].length == 0) {
              $('#myinvoiceModal').modal('hide');
              console.log('iam empty');

            }
          } else {
            //  this.modalclose()
            $(document).ready(function() {


              $('#myinvoiceModal').modal('hide');

            });
            //  if(res.body['error']=='false'){
            //           console.log('iam empty');
            //           $('#myinvoiceModal').modal('hide');
            //  }
          }
          //  })

        } else {

        }
      })

    }, 5000);

    // common invoice modal end
  }

  getCarddetails() {

    // debugger
    // this.cookie.set('userlist',page);
    // localStorage.setItem('userlist',page);
    // this.appspinner.spinnerAlert('show');

    this.commonservice.listCard().subscribe((res) => {
      if (res['error']) {

        environment.swalalert('nodata', res['msg']);
        // this.appspinner.spinnerAlert('hide');
      } else {
        this.cardmodel = new CardDetailViewModel(res.body['error'], res.body['msg'], res.body['list_card_details']);
        if (res.body['list_card_details']) {
          console.log('result', res.body['list_card_details'].length);
          // this.page = page;
          // this.pages = this.cardmodel['data'][1].Count;
          // this.result = this.cardmodel['list_card_details'].list_card_details;

          this.result = this.cardmodel['list_card_details'];

          console.log('result123', this.result);


        } else {
          environment.swalalert('nodata', 'No Data Available ');
        }
      }
    },
      (err) => {
        console.log(err);
      })
  }

  onChange({ type, event }) {
    if (type === 'change') {
      this.stripeCardValid = event.complete;
    }
  }

  buy() {
    // debugger
    this.stripeService
      .createToken(this.card.getCard(), { name: this.paymentForm.value.name })
      .subscribe(result => {
        if (result.token) {
          console.log('token', result.token);
          var source = result.token.id;
          var cardId = { source: source }
          this.commonservice.saveCardId(cardId).subscribe(res => {
            if (res.body['error'] == 'false') {
              environment.swalalert('success', res.body['msg'])
              cardId = res['body']['data'];
              this.getCarddetails()
            } else {
              environment.swalalert('error', res.body['error_message']);

            }
          });
        } else if (result.error) {
          console.log(result.error.message);
        }

      });
  }




  startjobendjobdetails() {
    const apidata: any = { booking_id: this.modelview.booking_id }

    this.commonservice.startjobendjobdetails(apidata).subscribe(res => {
      if (res.body['error'] == 'false') {
        this.jobimage = res['body']['data'];
        this.invoice = res['body']['invoicelink'][0].invoicelink;
        console.log(res['body']['invoicelink'][0].invoicelink);


      } else {
        environment.swalalert('error', res.body['error_message']);
      }
    });
  }
  //common modal functions
  paymentmethod(data) {
    this.paymenttypes.forEach(element => {
      element.addressactive = false
    });
    data.addressactive = !data.addressactive;
    if (data.payment_name == 'cash') {
      this.iscash = true
      this.iscard = false
    } else {
      this.iscash = false
      this.iscard = true
    }
  }



  sendreview(data) {
    console.log('review', data);


    const apidata: any = {}
    apidata.booking_id = data.booking_id
    apidata.id = data.provider_id
    apidata.rating = this.rating;
    apidata.feedback = this.feedback
    this.commonservice.sendreview(apidata).subscribe(res => {
      if (res.body['error'] == 'false') {
        this.rating = undefined;
        this.feedback = undefined;
        // window.location.reload();

        this.thankstext = true;
        this.modelview.status = 'Thanks'
        $(document).ready(function() {


          $('#myinvoiceModal').modal('hide');

        });
        environment.swalalert('success', res.body['msg']).then(value => {
          //  $("[data-dismiss=modal]").trigger({ type: "click" });

        });
      } else {
        environment.swalalert('error', res.body['error_message']);
      }
    })
  }
  Thanks() {
    this.thankstext = false;
    window.location.reload();
  }
  selectcard(id) {
    this.selectedcardid = id
  }
  confirmpayment(data) {
    // debugger
    const apidata: any = { id: data }
    if (this.iscash) {
      apidata.method = 'cash'
      this.commonservice.pay(apidata).subscribe(res => {
        if (res.body['error'] == 'false') {
          environment.swalalert('success', res.body['msg']).then(value => {
            this.waitingforconfirmation = true;
          });
        } else {
          environment.swalalert('error', res.body['error_message']);
        }
      })
    } else if (this.iscard) {
      apidata.method = 'card'
      apidata.amount = this.modelview.total_cost
      apidata.token = this.selectedcardid
      this.commonservice.stripe(apidata).subscribe(res => {
        if (res.body['error'] == 'false') {
          environment.swalalert('success', res.body['msg']).then(value => {
            this.waitingforconfirmation = true;
          });
        } else {
          environment.swalalert('error', res.body['error_message']);
        }
      })
    }
  }
  applycoupon(data) {

    const apidata = { couponname: this.couponcode, booking_order_id: data }
    this.commonservice.apply_coupon(apidata).subscribe(res => {
      if (res.body['error'] == 'false') {
        this.iscouponapplied = true;
        environment.swalalert('success', res.body['msg']).then(value => {
          //  $("[data-dismiss=modal]").trigger({ type: "click" });

        });
      } else {
        environment.swalalert('error', res.body['error_message']);
      }
    })

  }
  removecoupon(data) {

    const apidata = { couponname: this.couponcode, booking_order_id: data }
    this.commonservice.remove_coupon(apidata).subscribe(res => {
      if (res.body['error'] == 'false') {

        this.iscouponapplied = false;
        this.couponcode = undefined;
        environment.swalalert('success', res.body['msg']).then(value => {
          //  $("[data-dismiss=modal]").trigger({ type: "click" });

        });
      } else {
        environment.swalalert('error', res.body['error_message']);
      }
    })


  }

  removecard(id) {
    var card_details = {
      source: id
    }
    this.commonservice.deleteCard(card_details).subscribe(res => {
      if (res.body['error'] == "false") {
        environment.swalalert('success', res.body['msg']).then(value => {
          if (value) {
            this.getCarddetails()
          }
        });
      } else {
        environment.swalalert('error', res.body['msg']);
      }
    });

  }


  modalclose() {
    $(document).ready(function() {


      $('#myinvoiceModal').modal('hide');

    });

    //  $('#myinvoiceModal').modal('hide');

  }
  listpaymentmethod() {
    // debugger
    this.commonservice.list_payments().subscribe(res => {
      if (res.body['error'] == 'false') {
        this.paymenttypes = res.body['payment_types'];
        console.log(this.paymenttypes);
        this.paymenttypes.map(res => {
          if (res.payment_name == 'cash') {
            res.faicon = '<i class="fa  fa-money-bill fa-7x" style="color: black;cursor: pointer;" ></i>'
          } else if (res.payment_name == 'card') {
            res.faicon = '<i class="fa  fa-credit-card fa-7x" style="color: black;cursor: pointer;" ></i> '
          }

        })

      } else {
        environment.swalalert('error', res.body['error_message']);
      }
    })
  }
  ngOnDestroy(): void {
    clearInterval(this.commonservice.interval);

  }
}
