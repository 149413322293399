import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  url = environment.serverurl;


 
  

 
  
  constructor(private http:HttpClient,
    private cookie: CookieService) { }
    
   
    chatlist(data){
    
     const httpheaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
     
      });
    //  http://35.176.119.221:3000/userchatlist
      return this.http.post( `${this.url}chat/userchatlist` ,data
      ,{
        headers: httpheaders,
            observe: 'response'
      }
      );
    }
}
