import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotfoundpageComponent } from './notfoundpage/notfoundpage.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { HomepageComponent } from './landingpage/homepage/homepage.component';
import { MultiloginComponent } from './multilogin/multilogin.component';
import { NavbarComponent } from './landingpage/navbar/navbar.component';
import { ProviderComponent } from './provider/provider.component';
import { UserComponent } from './user/user.component';
import { ProviderregisterComponent } from './provider/providersignup/providerregister/providerregister.component';
import { ProvidersignupComponent } from './provider/providersignup/providersignup.component';
import { UsersignupComponent } from './user/usersignup/usersignup.component';
import { SearchComponent } from './search/search.component';
import { ViewprofileComponent } from './viewprofile/viewprofile.component';
import { AboutUsComponent } from './landingpage/about-us/about-us.component';
import { AccountnavbarComponent } from './landingpage/accountnavbar/accountnavbar.component';
import { AccountComponent } from './landingpage/account/account.component';
import { GuardGuard } from './guard';
import { HomePageNewComponent } from './landingpage/home-page-new/home-page-new.component';
import { ScheduleComponent } from './landingpage/schedule/schedule.component';
import { ProvidersComponent } from './landingpage/providers/providers.component';
import { ProviderviewComponent } from './landingpage/providerview/providerview.component';

import { ForgotpasswordComponent } from './user/forgotpassword/forgotpassword.component';
import { VerifyotpComponent } from './user/verifyotp/verifyotp.component';
import {ResetpasswordComponent } from './user/resetpassword/resetpassword.component';
import { ChatlistComponent } from './chatlist/chatlist.component';


// const routes: Routes = [
//   {path: '', component: HomepageComponent},
//   {path: 'multilogin', component: MultiloginComponent}
// ];

const routes: Routes = [
  {path: '', component: LandingpageComponent,
  children : [
    {path: 'nav', component: NavbarComponent, canActivate:[GuardGuard]},
    {path: 'home', component: HomePageNewComponent, canActivate:[GuardGuard]},
    {path: 'aboutUs', component: AboutUsComponent, canActivate:[GuardGuard]},
  ]},
  {path: 'multilogin', component: MultiloginComponent},
  {path: 'provider', component: ProviderComponent},
  {path: 'user', component: UserComponent},
  {path: 'providersignup', component: ProvidersignupComponent},
  {path: 'usersignup', component: UsersignupComponent},
  {path: 'userforgotpassword', component: ForgotpasswordComponent},
  {path: 'userverifyotp', component: VerifyotpComponent},
  {path: 'userresetpassword', component: ResetpasswordComponent},
  {path: 'search', component: SearchComponent, canActivate:[GuardGuard]},
  {path: 'viewprofile', component: ViewprofileComponent, canActivate:[GuardGuard]},
  {path: 'account-profile', component: AccountnavbarComponent, canActivate:[GuardGuard]},
  
  {path: 'myaccount', component: AccountComponent, canActivate:[GuardGuard]},
  {path: 'schedule', component: ScheduleComponent, canActivate:[GuardGuard]},
  {path: 'providers', component: ProvidersComponent, canActivate:[GuardGuard]},
  {path: 'providerview', component: ProviderviewComponent , canActivate:[GuardGuard]},

  {path: 'chat', component:ChatlistComponent , canActivate:[GuardGuard]},
];




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  
})
export class AppRoutingModule { }
