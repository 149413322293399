import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserregisterService {

    url = environment.Url;
  httpheaders = new HttpHeaders({
 
    'Content-Type': 'application/json',
   'Accept': 'application/json'
   
  });

  constructor(private http:HttpClient,
    private cookie: CookieService) { }

     // UserRegisterAdd
     UserRegisterAdd(data){
          
          return this.http.post(`${this.url}signup`,data,{
            headers: this.httpheaders,
            observe: 'response'
          });
        } 
}
