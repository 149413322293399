export class Userlogin {
    public email : String;
    public password : any;

    constructor( 
        email: String,
        password: any
         ) {
    this.email = email;
    this.password = password;
   
    }
}
