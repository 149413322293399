import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  url = environment.Url;
  httpheaders = new HttpHeaders({
    'Authorization': "Bearer " + this.cookie.get('Token'),
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  httpheadersimage = new HttpHeaders({
    // 'Content-Type': [],
    // 'Accept': 'application/json',
    'Authorization': 'Bearer ' + this.cookie.get('Token').split('|')[0]
  });

  constructor(private http: HttpClient,
    private cookie: CookieService) { }

  listaddress() {
    return this.http.get(`${this.url}appsettings`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  useraddress() {
    return this.http.get(`${this.url}listaddress`, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }

  fileupload(data: File) {

    const formData: FormData = new FormData();
    formData.append('file', data, data.name);
    console.log('formData', formData)

    return this.http.post(`${this.url}fileupload`, formData, {
      headers: this.httpheadersimage,
      observe: 'response'
    });
  }


  // fileupload(data) {
  //   debugger

  //   // var formData = new FormData();
  //   // Array.from(data).forEach(f => formData.append('file', f))
  //   // const formData = new FormData();

  //   // for (let i = 0; i < data.length; i++) {
  //   //   formData.append('files[]', data[i], data[i].name);
  //   // }

  //   return this.http.post(`${this.url}imageupload`, data, {
  //     headers: this.httpheadersimage,
  //     observe: 'response'
  //   });
  // }

  // fileupload(formData: FormData): Promise<any> {  
  //   debugger

  //   return this.http.post(`${this.url}imageupload`, formData, {
  //     headers: this.httpheadersimage,
  //     observe: 'response'
  //   }).toPromise();
  // }
  





  addaddress(data) {

    return this.http.post(`${this.url}addaddress`, data, {
      headers: this.httpheaders,
      observe: 'response'
    });
  }
}
