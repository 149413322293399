export class Homepage {
    public id : number;
    public category_name : String;
    public sub_category_name : String;

    constructor( 
        id: number,
        category_name: String,
        sub_category_name: any
         ) {
             this.id = id;
    this.category_name = category_name;
    this.sub_category_name = sub_category_name;
   
    }
}
