import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multilogin',
  templateUrl: './multilogin.component.html',
  styleUrls: ['./multilogin.component.css']
})
export class MultiloginComponent implements OnInit {

 
  constructor() {
    
   }

  ngOnInit() {
    
  }
  
}
