import { Component, OnInit ,ViewChildren, ElementRef, OnDestroy} from '@angular/core';
import { Router, ActivatedRoute,RouterLinkActive } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';



import { UserService } from './../user.service';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { reject } from 'q';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery' 

@Component({
  selector: 'app-verifyotp',
  templateUrl: './verifyotp.component.html',
  styleUrls: ['./verifyotp.component.css']
})
export class VerifyotpComponent implements OnInit,OnDestroy {

  otpverificationForm : FormGroup;
 
forgotemail:any
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
@ViewChildren('formRow') rows: any;
el:any;
  constructor(private formBuilder : FormBuilder,
    private router : Router,
    private route : ActivatedRoute,
    private service : UserService,
    private cookie: CookieService) { }

  ngOnInit() {

   
this.forgotemail=localStorage.getItem('forgotemail');
    this.otpverificationForm =   this.toFormGroup(this.formInput);
console.log('rows',this.rows);

 


  }
  ngOnDestroy(){
    //localStorage.removeItem('forgotemail');
  }
  toFormGroup(elements) {
    const group: any = {};
    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
   }
   keyUpEvent(event, index) {
     console.log(event,index);
     
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
     pos = index - 1 ;
    } else {
     pos = index + 1 ;
    }
    if (pos > -1 && pos < this.formInput.length ) {
     this.rows._results[pos].nativeElement.focus();
    }
   }
  validateotp(){
    // console.log(this.userLoginForm.controls);

const otpdata:any =`${this.otpverificationForm.value.input1}${this.otpverificationForm.value.input2}${this.otpverificationForm.value.input3}${this.otpverificationForm.value.input4}${this.otpverificationForm.value.input5}${this.otpverificationForm.value.input6}`


const apidata={email:this.forgotemail,otp:otpdata}
console.log(apidata); 

      if(this.otpverificationForm.valid)
      {
       
        
        this.service.forgotpasswordotpcheck(apidata).subscribe((result) =>{
        
          if(result.body['error'] == 'false')
          {
          
  
            environment.swalalert('success',result.body['otp']).then(value => {
              if(value){
                this.router.navigate(['/userresetpassword'])
              }
            });
          }else {
            environment.swalalert('error',result.body['error_message']);
            }
          
        },
          (error) => {
            console.error(error);
            reject(error);
          }
        );
      }else{
        environment.swalalert('error','Validation Required');  
      }
    }

}
