import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { map, filter, retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthinterceptorService {
    
  constructor(public router: Router,public cookie:CookieService) { }
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    var accessToken = this.cookie.get('Token')
    var httpHeaders 
   const serverurl=environment.serverurl;
  // console.log(`${serverurl}chat/userchatlist`);
   
    if(httpRequest.url!==`${serverurl}chat/userchatlist`){
      console.log('through interceptor');
      
      httpHeaders = {
        // 'Content-Type': 'application/json',
       //  'Accept': 'application/json',
         'Authorization': "Bearer "+accessToken,
       };
    }

    // if(!this.cookie.get('Token')){
    //   this.router.navigate(['user']);
    // }
  //  console.log('intercept request',httpRequest.url);
    
  //  if (httpRequest.url != 'http://139.59.6.161/uberdoo_angulartest/public/api/homedashboardnew')
    return next.handle(httpRequest.clone({setHeaders:httpHeaders  })).pipe( retry(2), catchError( (error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        if(error.status == 401){
          localStorage.clear();
          this.router.navigate(['user']);
        }
        return throwError(errorMessage);
      })
    )
  }
}