import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    url = environment.Url;
    interval: any;
    httpheadersimage = new HttpHeaders({
        'Authorization': 'Bearer ' + this.cookie.get('Token').split('|')[0]
    });

    // httpheaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
    //   'Authorization': 'Bearer '+ this.cookie.get('Token').split('|')[0]
    // });
    httpheaders = new HttpHeaders({
        'Authorization': "Bearer " + this.cookie.get('Token'),
        'Content-Type': 'application/json',
        'Accept': 'application/json',


    });
    constructor(private http: HttpClient,
        private cookie: CookieService) { }


    list_payments() {

        return this.http.get(`${this.url}list_payment_methods`, {

            headers: this.httpheaders,
            observe: 'response'
        })
    }
    apply_coupon(data) {

        return this.http.post(`${this.url}couponverify`, data, {
            headers: this.httpheaders,
            observe: 'response'
        });
    }

    listCard() {
        // debugger
        return this.http.get(`${this.url}listCard`, {

            headers: this.httpheaders,
            observe: 'response'
        })
    }
    saveCardId(data) {
        // debugger
        return this.http.post(`${this.url}saveCard`, data, {
            headers: this.httpheaders,
            observe: 'response'
        });
    }

    remove_coupon(data) {

        return this.http.post(`${this.url}couponremove`, data, {
            headers: this.httpheaders,
            observe: 'response'
        });
    }

    deleteCard(data) {

        return this.http.post(`${this.url}deleteCard`, data, {
            headers: this.httpheaders,
            observe: 'response'
        });
    }

    // Viewbookings
    app_settings() {
        return this.http.get(`${this.url}appsettings`, {
            headers: this.httpheaders,
            observe: 'response'
        });
    }
    pay(data) {
        return this.http.post(`${this.url}pay`, data, {
            headers: this.httpheaders,
            observe: 'response'
        });
    }
    stripe(data) {
        return this.http.post(`${this.url}stripe`, data, {
            headers: this.httpheaders,
            observe: 'response'
        });
    }

    startjobendjobdetails(data) {
        return this.http.post(`${this.url}startjobendjobdetails`, data, {
            headers: this.httpheaders,
            observe: 'response'
        });
    }
    sendreview(data) {
        return this.http.post(`${this.url}review`, data, {
            headers: this.httpheaders,
            observe: 'response'
        });
    }
}
